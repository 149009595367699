<template>
  <div>
    <div class="block banner bg pssl">
      <div class="container">
        <div class="box">
          <h1>{{$t('productSsl')}}</h1>
          <p>{{$t('product105')}}</p>
          <p class="mobile des">{{$t('product106')}}</p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :span="17">
            <h2>{{$t('product107')}}</h2>
            <p>{{$t('product106')}}</p>
            <p>{{$t('product108')}}</p>
          </el-col>
          <el-col :span="7">
            <OtherList hideRoute="productssl" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product109')}}</h2>
        <ul class="list1">
          <li class="icon36">
            <h5>{{$t('product110')}}</h5>
            <p>{{$t('product111')}}</p> 
          </li>
          <li class="icon52">
            <h5>{{$t('product112')}}</h5>
            <p>{{$t('product113')}}</p> 
          </li>
          <li class="icon38">
            <h5>{{$t('product114')}}</h5>
            <p>{{$t('product115')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image16.svg" /></div>
            <div class="cont">
              <h4>{{$t('product116')}}</h4>
              <p>{{$t('product117')}}</p>
            </div>
          </li>
        </ul>
        </div>
      </div>
        <div class="block gray"> 
          <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image17.svg" /></div>
            <div class="cont">
              <h4>{{$t('product118')}}</h4>
              <p>{{$t('product119')}}</p>
            </div>
          </li>
          </ul>
        </div>
          </div>
          <div class="block">
            <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image18.svg" /></div>
            <div class="cont">
              <h4>{{$t('product120')}}</h4>
              <p>{{$t('product121')}}</p>
            </div>
          </li>
        </ul>
        
        <hr/>
        <ApplyBox />
      </div>
      </div>
    </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
export default {
  name: "ProductSsl",
  components: {
    OtherList,
    ApplyBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('productSsl')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }

  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
